<template>
  <div class="myPage">
    <myHeader />
    <div class="myPageContent">
      <div class="pageLeft">
        <div v-for="dep, index in arrDepName" :key="dep" @click="onDeptClick(index)" class="depName"
          :class="index == curIdx ? 'depNameActive' : ''">
          {{ dep }}
        </div>
      </div>
      <div class="pageRight">
        <div v-for="pro in project" :key="pro">
          <div class="proName">
            <div>课程名称：{{ pro.name }}</div>
          </div>
          <img class="proCover" :src="pro.cover">
          <div class="subTitle">课程介绍：</div>
          <div class="proDesc">{{ pro.description }}</div>
          <div class="subTitle">学习任务资料：</div>
          <div class="proCourse">
            <div class="itemCourse" v-for="cour in pro.course" :key="cour" @click="onCourseClicked(cour)">
              <img :src="cour.cover" alt="">
              <div class="nameCourse">{{ cour.name }}</div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <myFooter />
  </div>
</template>

<style lang="scss" scoped>
@import '../assets/variables.scss';

.myPageContent {
  width: 100%;
  display: flex;
  padding: 10px 20px;
  overflow: hidden;
}

.pageLeft {
  width: 12%;
  min-width: 80px;
  flex-shrink: 0;
  padding: 0 10px;
}

.depName {
  background-color: white;
  margin-bottom: 10px;
  padding: 8px 0;
  border-radius: 2px;
}

.depNameActive {
  background-color: $primary-color;
  color: white;
}

.pageRight {
  flex-grow: 1;
  text-align: left;
  background-color: white;
  padding: 10px 20px;
  border: 1px solid #eeeeee;
  overflow-y: scroll;
  overflow-x: hidden;
}

.proName {
  color: $primary-color;
  padding: 6px 0;
  font-weight: bold;
}

.proCover {
  width: 40%;
  min-width: 400px;
  max-width: 90%;
  margin: 10px 0;
}

.proDesc {
  font-size: 0.8rem;
  line-height: 1.4rem;
  padding: 10px;
}

.proCourse {
  display: flex;
  flex-wrap: wrap;
}

.itemCourse {
  width: 22%;
  padding: 6px 0;
  margin: 10px 3% 10px 0;
  border-radius: 4px;
  border: 1px solid #eee;
}

.itemCourse img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.nameCourse {
  font-size: 0.8rem;
  line-height: 2rem;
  padding: 10px 10px 0 10px;
  border-top: 1px solid #eee;
}
</style>


<script setup>
import myHeader from '@/components/myHeader.vue'
import myFooter from '@/components/myFooter.vue'
import axios from 'axios'
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter();

const arrDepName = ref([]);
const project = ref(null);
const curIdx = ref(0);
let retData = null;

// 页面加载后，获取缓存中的curIdx的值
onMounted(() => {
  curIdx.value = localStorage.getItem('curIdx') || 0;
  getInfo();
})


function getInfo() {
  axios.get('../data/info.json')
    .then(res => {
      retData = res.data.data;

      if (retData != null && retData.length > 0) {
        // 获取二级学院列表数据
        for (let i = 0; i < retData.length; i++) {
          arrDepName.value.push(retData[i].name)
        }
        // 获取二级学院列表数据
        project.value = retData[curIdx.value].project
      }
    })
    .catch(err => {
      console.log(err);
    })
}

function onDeptClick(idx) {
  if (curIdx.value != idx) {
    curIdx.value = idx;
    project.value = retData[curIdx.value].project
  }
}

function onCourseClicked(data) {
  // 将curIdx的值存入缓存中
  localStorage.setItem("curIdx", curIdx.value);
  // 将data存入缓存中
  localStorage.setItem("course", JSON.stringify(data));
  // 跳转到课程详情页面
  router.push("/detail")
}

</script>